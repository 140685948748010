
import {
  Mixins,
  Component, 
  Ref, ProvideReactive
} from "vue-property-decorator";
import tablePagination from "@/mixins/tablePagination";
import loading from "@/mixins/loading";
import Resize from "@/mixins/resize";

import ETable from "@/components/ETable.vue";

import AddProductType from "./components/addProductType.vue";

import { presCategoryTypeListPlus, updatePresCategoryStatus } from "@/api/prescriptionDb"

import { cloneDeep } from "lodash";


@Component({
  inject: [],
  components: { ETable, AddProductType }
})
export default class PrescriptionProductCategory extends Mixins(
  tablePagination,
  loading,
  Resize
) {
    @Ref("AddProductType") readonly addProductTypeRef!: AddProductType;

    @ProvideReactive()
    merchantId = 0;

    productTypeOptions = [{ label: "项目产品库", value: 1 }, { label: "居家产品库", value: 2 }, { label: "其他库", value: 3 }]

    queryForm = {
        status: "",
        productCategory: "",
        name: "",
    }

    formData = {
        parentId: undefined,
        deviceClassId: "",
        name: "",
        status: true,
        sort: 0,
    }

    statusOptions = [
        { label: "启用", value: 1 },
        { label: "禁用", value: 0 }
    ]

    columns = [
        { label: "ID", prop: "id", width: 70, align: "center" },
        { label: "产品分类名称", prop: "name", align: "center",  },
        { label: "产品类型", prop: "", align: "center", formatter: (row) => this.productTypeOptions?.find(el => row.parentId === el.value)?.label },
        { label: "排序", prop: "sort", align: "center" },
        { label: "状态", prop: "", align: "center", formatter: (row) => !row.status ? "禁用" : "启用" },
        { label: "操作人", prop: "modifiedBy", align: "center" },
        { label: "最后操作时间", prop: "modifiedTime", align: "center" },
        { label: "操作", prop: "action", align: "center" },
    ]

    tableData = [];

    mounted() {
        this.windowResize(300);
        this.getData();
    }
    
    getData() {
      const params = {
          pageNum: this.currentPage,
          pageSize: this.pageSize,
          ...this.queryForm,
          categoryType: 1,
          merchantId: this.merchantId,
          parentId: this.queryForm.productCategory,
      }
      this.showLoading();
      presCategoryTypeListPlus(params).then((res) => {
        this.tableData = res.data.list;
        this.tableData.forEach((el) => {
          el.status = Boolean(el.status);
        })
        this.itemTotal = res.data.total;
      }).finally(() => {
        this.hideLoading();
      })
    }

    search() {
        this.resetCurrent();
        this.getData();
    }

    addCategory(){
        this.addProductTypeRef.dialogFormVisible = true;
        this.formData = {
          status: true,
          parentId: undefined,
          deviceClassId: "",
          name: "",
          sort: 0,
        }
    }

    refreshProductList() {
        this.getData();
    }

    edit(row) {
        this.addProductTypeRef.dialogFormVisible = true;
        this.formData = cloneDeep({ ...row, status: Boolean(row.status) });
    }

    enable(row) {
      this.$confirm(`是否${row.status ? "禁用" : "启用"}分类【${row.name}】`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
      }).then(() => {
        const params = { id: row.id, status: Number(row.status) }
        updatePresCategoryStatus(params).then(res => {
          this.$message.success("操作成功");
          this.search();
        })
      })
    }
}
